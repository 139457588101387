import React from 'react';
import './trial.css';

export const Trial = () => 
{
    return(
        <nav className='navHomepage'>
            {/* dont delete, global nav homepage for student and teacher */}
        </nav>
    );
}