export const MenuData = [
    {
        title: "About",
        url:"/about",
        cName:"nav-links",
        icon:"fa-solid fa-circle-info"

    },
    {
        title: "Services",
        url:"/services",
        cName:"nav-links",
        icon:"fa-solid fa-briefcase"
    },
    {
        title: "Contact",
        url:"/contact",
        cName:"nav-links",
        icon:"fa-solid fa-address-book"
    },
    {
        title: "LogIn",
        url:"/loginsignup",
        cName: "nav-links-LogIn"
    }
];